.ProfileSwitches{
    .nav-link{
        color: black;
        cursor: pointer;
    }

    .nav-link.active{
        color: #E32845;
    }

    button{
        border-radius: 30px;
    }
}

.ProfileSwitches button {
    border-radius: 30px;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .default-btn {
        font-size: 14px;
        padding: 10px 20px;
    }
}
.default-btn {
    background-color: #e32845;
    color: #fff;
    font-size: 16px;
    padding: 13px 35px;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    border: 1px solid #e32845;
    z-index: 1;
}
.float-end {
    float: right !important;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.register {
    max-width: 860px;
    margin: auto;
    padding: 50px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, .05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .10);
    margin-bottom: 30px;
}
