.col-lg-6  {
    position: relative;
    width: 100% !important;
    padding-right: 12px !important;
    padding-left: 12px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

    .font-weight-bold {
        font-weight: 600 !important;
        font-size: 14px !important;
        padding: 7px !important;
    }
    .float-right {
        float: right !important;
    }

    .attstudent{
        padding: 2px !important;
    }

    .attendancee td {
        padding: 10px !important;
        font-size: 15px !important;
    }

    .attendancee th {
        padding: 10px !important;
        font-size: 16px !important;
    }

    .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1rem !important;
    }

    
// print Staff Profile
.print-staff-details {
    color: #111d5e;
    font-family: "source serif pro", serif;
    font-weight: 700;
}

.print-stud-details {
    color: #111d5e !important;
    font-family: "source serif pro", serif;
    font-weight: 500 !important;
}

.staff-details {
    padding: 0rem !important;
    max-width: 88%;
    margin-left: 60px;
}

.image-container {
    height: 230px;
    width: 210px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    object-fit: fill;
    margin-left: 40px;
}

.stafff-img {
    vertical-align: middle;
    border-style: none;
    object-fit: fill;
    border: 1px solid black;
    width: 220px;
    height: 240px;
}

.table td, .table th {
    padding: 0rem !important;
    vertical-align: top;
    border-top: 1px solid white;
}

.table th {
    padding: 8px !important;
    font-size: 16px !important;
}

.table td {
    padding: 8px !important;
    font-size: 14px !important;
}

body {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1;
    color: #505d69;
    text-align: left;
    background-color: #f1f5f7;
    padding: 0.2rem !important;
}

.studatt th {
    padding: 3px !important;
}

.studatt td {
    padding: 3px !important;
}

.card-header {
    padding: .25rem 0.25rem !important;
    margin-bottom: 0;
    background-color: #f1f5f7;
    border-bottom: 0 solid #f1f5f7;
}