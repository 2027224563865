.Documents{
    table{
        tr{
            th{
                padding: 15px !important;
                border: 1px solid red;
            }
            td{
                padding: 15px !important;
                border: 1px solid red;
                color: black;
            }
        }
    }
}