// .main-content {
//     padding: 20px;
//   }

// .page-content {
//   background-color: #f8f9fa;
//   border-radius: 10px;
//   padding: 20px;
// }

// .card-header {
//   background-color: white;
//   color: rgb(0, 0, 0);
//   padding: 10px;
//   border-radius: 5px 5px 0 0;
// }

.subjects {
  margin-top: 20px;
}

.subjects th,
.subjects td {
  vertical-align: middle;
  text-align: center;
}

.btn-primary {
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}
