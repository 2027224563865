.AppovalWorkflow{
    .approval-flow{
           .approval-flow-title{
            font-size: 26px;
           }
           .approval-flow-arrow{
            font-size: 30px;
            color: var(--basicOrange);
            margin: 10px 0;
           }
    }    
}