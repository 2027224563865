@media print {
    /* Set page size to A4 */
    @page {
        size: A4;
        margin: 2mm; /* Adjust margin as needed */
    }

    /* Basic styling */
    body {
        background-color: white;
        color: black;
    }

    .heading {
        margin-top: 100px;
        color:black!important;
      
          
            padding-bottom: 20px; /* Adjust value for more or less space */
      

    }

    .print_footer_top {
        margin-top: 300px;
    }
    .print_footer_top_bulk {
        margin-top: 380px;
    }

    /* Ensure table borders are black */
    .table-bordered th, .table-bordered td {
        border-color: black !important;
    }
    
    th,td{
        color:black!important;

    }

    h6 {
        line-height: 2;
        color: black;
        font-weight: 400 !important;

    }
}
