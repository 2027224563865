.bt:hover {
  background-color: black;
  color: white;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1399px !important;
  }
}

/* body {
  background-color: white !important;
} */

/* .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem !important;
  border: 1px solid !important;
  border-radius: 6px;
} */

.ml-4,
.mx-4 {
  margin-left: -1rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111d5e;
  font-family: "source serif pro", serif;
  font-weight: 700;
}

.desktop-nav {
  padding-top: 0;
  padding-bottom: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.desktop-nav .navbar ul {
  margin-bottom: 0;
  list-style-type: none;
}
