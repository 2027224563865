.Footer{
    // position: absolute;
    bottom: 0;
    width: 100vw;
}

.copyright {
    padding: 17px 0;
}

.copyright-area {
    background-color: #1f2c75;
}

.copyright .social-content ul {
    padding-left: 0;
    margin-bottom: 0;
}

.copyright .copy p {
    color: #cacaca;
}
p:last-child {
    margin-bottom: 0;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.d-flex {
    display: flex !important;
}
p {
    margin-bottom: 15px;
    font-size: 15px;
    // color: #555;
    line-height: 1.7;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
*, ::after, ::before {
    box-sizing: border-box;
}
user agent stylesheet
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}
.copyright .copy {
    text-align: right;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}

.copyright .social-content ul {
    padding-left: 0;
    margin-bottom: 0;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol, ul {
    padding-left: 2rem;
}
*, ::after, ::before {
    box-sizing: border-box;
}

.copyright .social-content ul {
    padding-left: 0;
    margin-bottom: 0;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}