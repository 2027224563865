.ClassTimeTable{
    .table-row-gap{
        border-collapse: separate;
        border-spacing: 0 15px;
    }
}

.TeacherTimeTable{
    .table-row-gap{
        border-collapse: separate;
        border-spacing: 0 15px;
    }
}