// print Staff Profile
.print-staff-details {
    color: #111d5e;
    font-family: "source serif pro", serif;
    font-weight: 700;
}

.staff-details {
    padding: 0rem !important;
    max-width: 88%;
    margin-left: 60px;
}

.image-container {
    height: 260px;
    width: 230px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    object-fit: fill;
    margin-left: 40px;
}

.stafff-img {
    vertical-align: middle;
    border-style: none;
    object-fit: fill;
    border: 1px solid black;
    width: 230px !important;
    height: 260px !important;
}

.table td, .table th {
    padding: 0rem !important;
    vertical-align: top;
    border-top: 1px solid white;
}

.table th {
    padding: 0rem !important;
}

.table td {
    padding: 0rem !important;
}

body {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1;
    color: #505d69;
    text-align: left;
    background-color: #f1f5f7;
    padding: 0.2rem !important;
}

.table td, .table th {
    padding: 0.3rem !important;
    font-size: 13px !important;
}
td h4 {
    font-size: 1rem !important;
}
