.inputBox input{
    width: 100%;
    padding: 10px;
    /* border: 0.5px solid black; */
    border-radius: 5px;
    outline: none;
    font-size: 1em;
}

.inputBox span {
    position: absolute;
    margin-left: 20px;
    left: 0;
    padding: 10px;
    pointer-events: none;
    font-size: 1.2em;   
    transition: 0.1s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
    color: #364277;
    transform: translateX(0px) translateY(-7px);
    font-size: 0.9em;
    padding: 0 10px;
    background: white;
    border-color: #364277;
}