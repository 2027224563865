/* Custom  styles for steps */
.custom-steps .completed-step .ant-steps-item-icon {
  background-color: rgb(114, 208, 114);
  border-color: rgb(84, 236, 84);
}

.custom-steps .current-step .ant-steps-item-icon {
  background-color: blue;
  border-color: blue;
}

.custom-steps .not-started-step .ant-steps-item-icon {
  background-color: lightgray;
  border-color: lightgray;
}

.custom-steps .ant-steps-item-title {
  color: gray;
}

.custom-steps .completed-step .ant-steps-item-title {
  color: rgb(115, 196, 115);
}

.custom-steps .current-step .ant-steps-item-title {
  color: blue;
}

.custom-steps .not-started-step .ant-steps-item-title {
  color: gray;
}

.custom-steps .ant-steps-item-content {
  color: gray;
}
.custom-steps .completed-step .ant-steps-item-icon {
  background-color: green;
  border-color: green;
}

.custom-steps .completed-step .ant-steps-item-icon > .ant-steps-icon {
  color: white;
}

.custom-steps .current-step .ant-steps-item-icon {
  background-color: blue;
  border-color: blue;
}

.custom-steps .not-started-step .ant-steps-item-icon {
  background-color: lightgray;
  border-color: lightgray;
}

.btn-prev,
.btn-next {
  margin-top: 20px;
}

/* Center the job application container and set width */
/* .job-application-container {
  max-width: 1300px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
} */

/* Center and style the steps */
.steps-container {
  margin-bottom: 30px;
  text-align: center;
}

/* Style the form container */
.form-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

/* Flexbox layout for buttons */
.steps-action {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  margin-top: 20px;
}

/* Style for buttons */
.btn-prev {
  background-color: #b3b3b3;
  color: white;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-prev:hover {
  background-color: #999 !important;
}

.btn-next {
  background-color: #1890ff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-next:hover {
  background-color: #0056b3 !important;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.form-grid .ant-form-item {
  margin-top: 16px;
}

.job-application-container .ant-steps-item-title {
  font-size: 14px;
  white-space: nowrap;
  line-height: 15px;
}
.custom-input,
.custom-select .ant-select-selector {
  height: 40px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
  padding: 4px 11px !important;
  display: flex;
  align-items: center;
}
.danger {
  background-color: red;
}

.custom-select .ant-select-selector {
  padding: 4px 11px !important;
  display: flex;
  align-items: center;
}

.custom-select .ant-select-arrow {
  display: flex;
  align-items: center;
}
.add-new-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
