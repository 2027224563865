.Staff{
    .rowData{

        .col-12{
            font-size: 18px;
            color: var(--basicBlue);
        }
        .jobPos{
            color: black;
        }
        .col-md-6{
            margin: 8px 0px;
        }
    }

    .cursor-pointer{
        cursor: pointer;
    }

    .input-btn{
        position: absolute;
        cursor: pointer;
        top: 11px;
        right: 22px;
    }
}



// print Staff Profile
.print-staff-details {
    color: #111d5e;
    font-family: "source serif pro", serif;
    font-weight: 700;
}

.staff-details {
    padding: 0rem !important;
    max-width: 88%;
    margin-left: 60px;
}

.image-container {
    height: 260px;
    width: 230px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    object-fit: fill;
    margin-left: 40px;
}

.stafff-img {
    vertical-align: middle;
    border-style: none;
    object-fit: fill;
    border: 1px solid black;
    width: 230px;
    height: 260px;
}

.table td, .table th {
    padding: 0rem !important;
    vertical-align: top;
    border-top: 1px solid white;
}

.table th {
    padding: 0rem !important;
}

.table td {
    padding: 0rem !important;
}

body {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1;
    color: #505d69;
    text-align: left;
    background-color: #f1f5f7;
    padding: 0.2rem !important;
}