.horizontal-timeline .ant-timeline {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 20px 0;
  }
  
  .horizontal-timeline .ant-timeline-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 10px;
    padding: 0;
    position: relative;
  }
  
  .horizontal-timeline .ant-timeline-item-tail {
    display: none;
  }
  
  .horizontal-timeline .ant-timeline-item-head {
    margin: 0;
  }
  
  .horizontal-timeline .timeline-content {
    text-align: center;
  }
  