.DashboardEmployee{
    .announcements{
        cursor: pointer;
        padding: 5px;
        border:2px solid rgb(133, 133, 226);
        transition: all 2s ease-in;
        margin-bottom: 8px;
        border-radius: 6px;
        .announcement-title{
            font-size: 16px;
            font-weight: bold;
        }
        
        &:hover{
            .announcement-title{
                color: var(--basicOrange);
                // font-size: 17px;
                // transform: scale(1.1);
            }
        }
    }
}