.auth-form-group-custom {
    position: relative !important;
}

.auth-form-group-custom .form-control {
    height: 60px !important;
    padding-top: 28px !important;
    padding-left: 60px !important;
}

.auth-form-group-custom label {
    position: absolute !important;
    top: 7px !important;
    left: 60px !important;
}

.auth-form-group-custom .auti-custom-input-icon {
    position: absolute !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    left: 19px !important;
    font-size: 24px !important;
    color: #e80000 !important;
}

.cursor-disable{
    cursor:not-allowed !important;
}

// .authentication-bg {
//     background-image: url('../../assets/images/collegeimage.jpg');
//     width: 100vh;
//     height: 100vh;
//     background-size: cover;
//     background-position: center;
// }

// .btn-block {
//     width: 10% !important;
//     text-align: center;
//     margin-left: 0px;
// }
// .btn-primary {
//     color: #fff;
//     background-color: #2c68f3;
//     border-color: #5664d2;
// }