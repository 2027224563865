.fixTableHead { 
  overflow-y: auto; 
  height: 1000px !important;
} 
.fixTableHead thead { 
  position: sticky; 
  top: 0; 
  background-color:rgb(59, 59, 59);
  z-index: 1000;
  color: white;
} 

/* .Scroll-overfolw-y { 
  overflow-x: auto; 
  width: 1470px !important;
} 
.fixTableHead thead { 
  position: sticky; 
  top: 0; 
  background-color:rgb(59, 59, 59);
  z-index: 1000;
  color: white;
}  */